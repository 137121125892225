import React from 'react'
import Navbar from '../inc/Navbar'
import marbel from "../images/srijonmarbelbetai.jpg"
import MarbelSrijon from "../images/marbelsrijon.jpg"
import {Link} from "react-router-dom";
function Marbels() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
      
      <div className="album py-4">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
  <li class="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
    <li class="breadcrumb-item"><Link to="/Floortiles" href="#">Floor Tiles</Link></li>
    <li class="breadcrumb-item"><Link to="/Walltiles" href="#">Wall Tiles</Link></li>
    <li class="breadcrumb-item active" aria-current="page"> Marble</li>
  </ol>
</nav>
</div>
</div>
</div>
      <div className="album py-0">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={marbel} className="card-img-top"   alt="SrijonMarbel" />
                            <div className="card-body">
                                
                                <h4 style={{color:"Darkblue"}} className="card-text"><center> <b>Srijon Marble - Betai</b></center></h4>
                          
                                <div className="d-flex justify-content-between align-items-center">
                               
                                       
                                        
                                      
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border- box shadow">
                        <img src={MarbelSrijon} className="card-img-top"   alt="MarbelSrijon" />
                            <div className="card-body">
                                <h4 style={{color:"Darkblue"}} className="card-text"><b> <center>Srijon Marble - Betai</center></b></h4>
                             
<div className="d-flex justify-content-between align-items-center">
                                
                                       
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
    </div>
  )
}

export default Marbels
