
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyDn006kEyofHzsG5L4-6AivPzBswIOxLec",
    authDomain: "srijonmarbel.firebaseapp.com",
    projectId: "srijonmarbel",
    storageBucket: "srijonmarbel.appspot.com",
    messagingSenderId: "438144171880",
    appId: "1:438144171880:web:9282b2b9e9fb616baca0dd",
    measurementId: "G-5HMVXW1493"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;