import React from 'react'
import {Link} from "react-router-dom";
function Navbar() {
  return (
    <div>
      <div className="fixed-top">
      <nav className="navbar navbar-expand-lg navbar-dark bg-secondary">
  <div className="container-fluid">
    <Link to="/" className="navbar-brand me-4" ><b>SSM</b></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/Marble" className="nav-link active" aria-current="page" >Marble</Link>
        </li>
        <li className="nav-item">
          <Link to="/Floortiles" className="nav-link active" aria-current="page">Floor Tiles</Link>
        </li>
        <li className="nav-item">
          <Link to="/Walltiles" className="nav-link active" aria-current="page">Wall Tiles</Link>
        </li>
        <li className="nav-item">
          <Link to="/Outdoortiles" className="nav-link active" aria-current="page">Outdoor Tiles</Link>
        </li>
        <li className="nav-item">
          <Link to="/Contact" className="nav-link active" aria-current="page">Contact</Link>
        </li>
      </ul>
     
    </div>
  </div>
</nav>
    </div>
    </div>
  )
}

export default Navbar
