import React from 'react'
import Navbar from "../inc/Navbar";
import {Link} from "react-router-dom";
import Slider from "../inc/Slider";
import Pdfe from "../images/SANITARYWARECATALOGUE.pdf";
import SelfieDesigner1 from "../images/SelfieDesigner.pdf";
import ELEGANT1 from "../images/ELEGANT.pdf";
import Kajaria1 from "../images/Kajaria.pdf";
import SRIJONCATALOGUE2022 from "../images/SRIJONCATALOGUE.jpg";
import SALFIEsrijon from "../images/SALFIE.jpg";
import SALFIEsrijon2 from "../images/sanitaryware.jpg";
import KJARIASRIJON1 from "../images/KJARIASRIJON.jpg";
import Walltiles from "../pages/Walltiles";
import Slide from '../pages/Slide';
import Map from "../pages/Map";
import ReactWhatsapp from "react-whatsapp";
function Home() {
  return (
    <div>
        <Navbar/>
        <br/>
        <div className="album py-0">
        <div className='container'>
            <div className='row'>
                <h5>Srijon Marble,  Best Tiles in Betai</h5>
            </div>
            </div>
        </div>
        <Slider/>

        <div style={{color: "Green"}} className="fixed-bottom "> <ReactWhatsapp number="+91  7479120135" className="btn btn-success" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"  fill="currentColor" class="bi bi-whatsapp"  viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg> Whatsapp  <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div></ReactWhatsapp></div>

        <div className="album py-2">
        <div className="container">

            <div className="row">
            <nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><Link to="/Floortiles" href="#">Floor Tiles</Link></li>
    <li class="breadcrumb-item"><Link to="/Outdoortiles" href="#">Outdoor Tiles</Link></li>
    <li class="breadcrumb-item active" aria-current="page">Home</li>
  </ol>
</nav>
</div>
</div>
</div>
    
        <div className="album py-0">
        <div className="container">

            <div className="row">
       
            <div className="col-md-6" >
   <div className="d-flex justify-content-between align-items-center">
   <div className="card mb-4 me-3 border-dark box">
                                <div className="a2zservice">
                            <img src={ KJARIASRIJON1} className="card-img-top"   alt="essco_Catlogue" />
                            <div class="card-body">
                          
                              
                                <p style={{ color: "Darkblue"}} className="card-text mb-2"><b><center>KAJARIA</center></b></p>
                        
                                <Link to="/">
                               
                                </Link>
                                
                               



</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">SRIJON MARBLE</h5>
    <a href={Kajaria1} className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                         
                         
                            </div>

                            <div className="card mb-4 me-2 border-dark box shadow">
                            <img src={SALFIEsrijon} className="card-img-top"   alt="SRIJONCATALOGUE" />
                            <div class="card-body">
                       <center>
                                <p style={{ color: "DarkSlateBlue	"}} className="card-text mb-2"><b>SALFIE</b></p>
                                </center>
                            
                                
                               

</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">SRIJON MARBLE</h5>
    <a href={SelfieDesigner1} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                         
                         
                            </div>
                           
                            <div className="d-flex justify-content-between align-items-center">
                            <div className="card mb-4 me-3 border-dark box shadow">
                            <img src={SALFIEsrijon2} className="card-img-top"   alt="cotobathware_catalogue" />
                            <div class="card-body">
                                <center>
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>SANITARYWARE</b></p>
                                </center>
                             
                                
                               

</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">SRIJON MARBLE</h5>
    <a href={Pdfe} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                         
                         
                            
                            <div className="card mb-4 me-0 border-dark box shadow">
                            <img src={SRIJONCATALOGUE2022} className="card-img-top"   alt="SRIJON_CATALOGUE" />
                            <div class="card-body">
                            <center>
                                <p style={{ color: "DarkSlateBlue		"}} className="card-text mb-2"><b>ELEGANT</b></p>
                                </center>
                              
                                
                              

</div>
                              <center>
    <h5 style={{color: "Gray"}} className="card-title">SRIJON MARBLE</h5>
    <a href={ELEGANT1} target="_blank" rel="noopener noreferrer" className="btn btn-outline-dark btn-sm mb-2" >Download Catalogue</a>
    </center>
                                            </div>
                                            </div>
                                            </div>



                           
                            <div className="col-md-6">                        
        
                                <div className="card mb-4 me-3 border-dark box ">
                              
                            <img src={SRIJONCATALOGUE2022} className="card-img-top"   alt="Jaqure_catlogue" />
                            <div class="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                       
                             
                                <div className="d-flex justify-content-between align-items-center">
                                
                               
</div>
</div>
                
                                            
                                            </div>
            </div>
                            
                         </div>
                
                         </div>
                            </div>
                            </div>
                            <Slide/>
                          
                            <Walltiles/>
                            <Map/>
    </div>
  )
}

export default Home
