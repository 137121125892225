import React from 'react'

function Map() {
  return (
    <div>
        <div className="album py-0">
        <div className='container'>
            <div className='row'>
         
            <iframe className="iframe-fluid" width="1100" height="430" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=%20Betai+(SRIJON%20&amp;%20SHREENIDHI%20MARBLE,%20RH57+84V,%20Babua%20Cinema%20Hall,%20Cinema%20Hall%20Rd,%20near%20by%20Betai,%20West%20Bengal%20741163)&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>


                </div>
                </div>
            </div>

    </div>
  )
}

export default Map





