import React from 'react'
import Image from "../images/headingsrijon.jpg";
import Srijontiles from "../images/SRIJONtiles.jpg";
import Srijonbasin from "../images/SRIJONMAIN.jpg";

function Slider() {
  return (
    <div>
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Image} className="d-block w-100" alt="headingsrijon"/>
    </div>
    <div className="carousel-item">
      <img src={Srijontiles} className="d-block w-100" alt="Srijontiles"/>
    </div>
    <div className="carousel-item">
      <img src={Srijonbasin} className="d-block w-100" alt="Srijonbasin"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider
